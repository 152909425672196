<template>
  <!-- 使用协议 -->
  <div class="hello">
    <div class="main min-height">
      <!-- 全职 -->
      <div v-if="edition == 0">
        24猎用户协议<br /><br />
        欢迎您使用24猎的服务！为使用24猎的服务，您应当阅读并遵守《24猎用户协议》和《24猎隐私条款》。<br />
        在您成为24猎使用用户，使用24猎提供的服务之前，请您认真阅读《24猎用户协议》（以下简称“协议”），更好地了解我们所提供的服务以及您享有的权利和承担的义务。您一旦开始使用24猎服务，即表示您已经确认并接受了本文件中的<br />
        全部条款。您不应当以24猎未对本协议以合理方式提醒用户注意或未根据用户要求尽到说明义务为理由而声称或要求法院或其它任何第三方确认相关条款非法或无效。本协议是由您（以下简称“用户”或“您”）与北京三门课科技有限公司<br />
        （以下简称“24猎”或“我们”）就提供的24猎（以下简称“本平台”或“24猎”）所订立的相关权利义务规范。本协议适用于本协议项下提供的24猎网页（hr24.com.cn）及相关移动客户端（包括IOS、安卓及已有或未来将新增的任何其他<br />
        移动客户端）等各类平台或媒介的服务。<br />
        一、注册和使用<br /><br />
        1、一旦您在登录页面勾选“阅读并同意接受用户协议及隐私政策”相关内容后，即表示您已经阅读并且同意与24猎达成协议，成为24猎的用户，并接受本协议中的全部条款以及《24猎隐私条款》和本平台内公布的所有条款的约束。<br />
        2、申请注册成为24猎的用户应同时满足下列全部条件：在注册之日以及此后使用24猎服务期间必须以招聘和/或求职为目的；应当是具备相应民事行为能力的自然人（十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人）、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人（以下统称"监护人"）应承担因此而导致的一切后果，且24猎有权注销您的账户，并向您及您的监护人索偿。<br />
        3、为了更好地享有24猎网络信息发布平台服务，证明用户主体资格的真实性，用户应遵守24猎机制的要求，向24猎提供本人真实、准确、最新及完整的资料；如注册并通过审核成为招聘用户，应保证及时更新本人提供的“单位名称、营业执照”等相关授权信息及材料，并确保前述授权的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通等均在使用本平台期间持续有效，不应提交任何违法或不良信息，相关资料如有变动，您应及时更新。您了解并同意，您有义务保持您提供信息的真实性及有效性，以便我们或其他用户与您联系。<br />
        4、若用户提供任何错误、不实或不完整的资料，或24猎有理由怀疑资料为错误、不实或不完整及违反用户使用条款的，或24猎有理由怀疑其用户资料、言行等有悖于招聘平台使用规则的，24猎有权暂停或终止该用户使用其账号，或暂停或终止提供24猎提供的全部或部分服务。<br />
        5、若用户故意提供虚假的身份信息、公司信息进行注册和开通账号，发布虚假招聘信息或者求职信息的，视为严重违反本协议，24猎有权暂停或终止该用户账号并停止提供服务。虚假注册、发布虚假信息给24猎造成经济、名誉等任何损失的，24猎将追究该用户的法律责任。<br />
        <br />
        二、账号安全<br />
        1、在完成用户注册、开通程序之后，用户将可以通过账号及密码进行登录。用户有义务保证密码及账号的安全。用户应对利用该密码及账号所进行的一切活动负全部责任，包括任何经由24猎发布、下载、发或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。<br />
        2、用户的密码或账号遭到未获授权的使用，或者发生其他任何安全问题时，用户应立即通知24猎。由于用户使用不当或者其他非因24猎导致的账号、密码泄漏，进而导致其资料、信息泄漏的，由用户承担其不利后果。<br />
        3、用户不得将账号主动告知第三方或提供给第三方进行使用，例如提供给第三方进行代为购24猎服务等。如因此造成用户隐私泄露或本平台损失的，用户应当承担相应的责任。<br />
        <br />
        三、服务说明<br />
        1、无论是付费服务还是24猎的免费服务均有有效期，有效期结束后服务将自动终止，且有效期不可中断或延期，所有付费业务均不退费。<br />
        2、对于利用24猎进行非法活动，或其言行（无论线上或者线下的）背离24猎招聘目的的，24猎将严肃处理，包括将其列入黑名单、将其被投诉的情形公之于众、删除用户账号等处罚措施，给24猎造成经济或者名誉等任何损失的，24猎将追究其法律责任。<br />
        3、24猎有权通过拨打电话、发送短信等方式，告知用户24猎服务相关的广告信息、促销优惠等营销信息，以及邀请用户进行注册。<br />
        4.用户应通过本平台使用相关服务，未经许可，不得通过其他第三方工具或运营平台获取24猎服务，包括但不限于通过第三方软件登录24猎账号、发布职位、浏览职位、收发简历等。如因用户使用第三方软件导致相关信息泄漏的，24猎不承担任何责任，且用户还应承担由此给24猎造成的损失。<br />

        四、责任条款<br />
        1、24猎将尽力为用户提供提供安全、及时、准确、高质量的服务，但不保证一定能满足用户的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性都不作保证。除非另有约定，否则用户因无法使用24猎服务，或使用服务未达到心理预期的，24猎不承担责任。<br />
        2、对于用户通过24猎提供的服务传送的内容，包括但不限于照片、资料、证件、视频、内容及图片等，24猎会尽合理努力按照国家有关规定严格审查，但无法完全控制经由软件网站服务传送的内容，不保证内容的正确性、完整性或品质。因此用户在使用24猎服务时，可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，24猎均不为用户经由软件/网站服务以发布、张贴、发送电子邮件或其它方式传送的任何内容负责。但24猎有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用软件/网站服务的全部或部分，保存有关记录，并根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。<br />
        3、对于24猎提供的各种第三方广告信息、链接、资讯等（如有），24猎不保证其内容的正确性、合法性或可靠性，相关责任由广告主承担；并且，对于用户经由24猎服务与广告主进行联系或商业往来，完全属于用户和广告主之间的行为，与
        24猎无关。对于前述商业往来所产生的任何损害或损失，24猎不承担任何责任。<br />
        4、用户应对24猎上的其他用户发布的内容自行加以判断，并承担因使用内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。24猎无法且不会对因前述风险而导致的任何损失或损害承担责任。<br />
        5、对于24猎在线上或线下策划、发起、组织或是承办的任何招聘相关的活动，24猎不对上述招聘效果向用户作出任何保证或承诺，也不担保活动期间用户自身行为的合法性、合理性。由此产生的任何对于用户个人或者他人的人身或者是名誉以及其他损害，应由行为实施主体承担责任。<br />
        6、对于用户的投诉，24猎将尽合理努力进行核实和处理，但不保证一定能满足投诉者的要求。24猎有权决定是否向公众或向被投诉者公开投诉内容。对于投诉内容侵犯用户隐私权、名誉权等合法权益的，所有法律责任由投诉者承担，与24猎无关。<br />

        五、用户承诺<br />
        1、本协议所称“平台使用”是指用户使用本平台服务所进行的任何行为，包括但不限于注册、登录、查看、下载、聊天、账号管理、发布招聘信息等所进行的一切行为。<br />
        2、24猎提醒用户在使用24猎服务时，应遵守《中华人民共和国民法典》《中华人民共和国著作权法》《全国人民代表大会常务委员会关于维护互联网安全的决定》《中华人民共和国保守国家秘密法》《中华人民共和国电信条例》《中华人民共和国计算机信息系统安全保护条例》《中华人民共和国计算机信息网络国际联网管理暂行规定》《计算机信息系统国际联网保密管理规定》《互联网信息服务管理办法》《计算机信息网络国际联网安全保护管理办法》《互联网电子公告服务管理规定》《网络安全法》《中华人民共和国劳动法》《中华人民共和国劳动合同法》《网络信息内容生态治理规定》等相关中国法律法规的规定。<br />
        3、在任何情况下，如果24猎有理由认为用户使用24猎服务过程中的任何行为，包括但不限于用户的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，24猎可在任何时候不经任何事先通知终止向该用户提<br />供服务。
        4、用户承诺在使用24猎期间，遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等底线。<br />
        5、您理解并同意，本平台仅为用户提供招聘信息分享、传播及获取招聘、求职机会的平台，您必须为自己的注册、开通账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。关于用户通过本平台上传、发布信息的具体规则，应阅读并遵守24猎职位信息发<br />布规则。
        6、您在成为企业用户后，即可就招聘目的（即雇主寻找雇员）在本软件上发布招聘信息，以及按照相关要求和权限，发布与招聘目的有关的信息。您理解并同意，在发布招聘信息时不应存在以下行为：<br />
        （1）
        发布任何违反法律法规及其他规范性文件的信息，包括但不限于属于任何非法的、骚扰性的、中伤性的、辱骂性的、恐吓性的、伤害性、庸俗、淫秽、有违社会公序良俗等的信息或言论；<br />
        （2）
        发布任何与求职无关或与招聘信息发布之目的不符合的意见、通知、商业广告等信息；<br />
        （3） 发布虚假、不准确、不完整或具有误导性的职位信息等内容；<br />
        （4） 发布任何与职位名称、职位描述不符合的信息；<br />
        （5） 以任何形式侵犯他人的版权、商标权、商业秘密或其他知识产权；<br />
        （6） 侵犯个人和社会大众的隐私安全；<br />
        （7）
        利用网站开设的功能或权限发布、编辑、传播违反法律法规及其他规范性文件、引人反感或有违风化的信息、言论或内容。<br />
        7、用户有权在网站或相关移动客户端中搜索求职者的简历，从而发现您感兴趣的求职者，并进一步沟通与了解。您应当按照法定或约定的目的或方式使用上述信息，您在查阅简历时不应存在以下行为：<br />
        （1）
        将任何服务中的信息用作任何商业目的，以及未经我们的同意，向任何其他第三方披露、许可、转让非公开信息或以类似方式使第三方知晓相关信息；<br />
        （2）
        未经我们的同意，将购买的服务产品（包括但不限于简历信息）用于任何除自身招聘以外的其他目的，或以任何方式泄露、授权或变相授权给任何第三方；<br />
        （3） 擅自打印、复制或以其他方式使用有关雇员的任何个人资料；<br />
        （4） 将我们提供的信息用于招聘以外的其他不合理目的。<br />
        <br />
        六、禁止行为<br />
        禁止用户在24猎平台或利用24猎提供的服务，制作、发送、复制、发布、传播违反国家相关法律法规、底线、管理规定、本平台规则的信息、从事违反前述规定/规则的活动，主要表现为：<br />
        （1）反对宪法所确定的基本原则的。<br />
        （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。<br />
        （3）损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的。<br />
        （4）破坏国家宗教政策，宣扬邪教和封建迷信的。<br />
        （5）散布谣言，扰乱社会秩序，破坏社会稳定的。<br />
        （6）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。<br />
        （7）侮辱或者诽谤他人，侵害他人合法权益的。<br />
        （8）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或有悖道德、令人反感的内容的。<br />
        （9）含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其他内容的。<br />
        （10）使用24猎服务的过程中，以任何方式危害求职者合法权益的。<br />
        （11）冒充任何人或机构，包含但不限于冒充24猎工作人员或以虚伪不实的方式陈述或谎称与任何人或机构有关的。<br />
        （12）发布、传播侵犯任何人的肖像权、名誉权、隐私权、专利权、商标权、著作权、商业秘密的信息或言论的。<br />
        （13）将病毒或其它计算机代码、档案和程序等可能对24猎系统造成伤害或影响其稳定性的内容制造虚假身份以误导、欺骗他人。<br />
        （14）跟踪或以其它方式骚扰其他用户的。<br />
        （15）未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。<br />
        （16）以任何方式干扰或企图干扰24猎的任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等，进行危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本平台系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称，修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，
        不得将用于上述用途的软件通过信息网络向公众传播或者运营。<br />
        （17）未能按照本平台的流程、规则进行注册、使用、审核或使用本服务的，违反本服务功能限制或运营策略，或采取任何措施规避前述流程、规则、限制或策略的。<br />
        （18）未经24猎的许可使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务和相关系统的。<br />
        （19）利用24猎账号或本平台服务从事，包括但不限于欺诈、传销、刷流量、好评、违法物品营销等任何违法兼职或犯罪活动的。<br />
        （20）仿冒、混淆他人账号昵称、头像、功能介绍或发布招聘内容等，或冒充、利用他人名义对外招聘的。<br />
        （21）未经24猎的许可，以任何目的自行或授权、允许、协助任何第三人对平台内的任何信息内容进行非法获取，用于商业用途或其他任何目的，或在未经24猎书面明确授权前提下，出售、出租、出借、散布、转移或转授权软件和服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益。
        “非法获取”是指采用包括但不限于“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得数据和信息内容的行为。<br />
        （22）为任何注册用户或非注册用户提供自动登录到本平台、代办或协助他人代办身份认证服务的或代售身份认证所需的相关材料或凭据等。<br />
        （23）任何导致或可能导致24猎与第三方产生纠纷、争议或诉讼的行为。<br />
        七、特别规定<br />
        1、用户如违反本协议内容，24猎有权依据本协议的约定，做出相应处理或终止向用户提供服务，在任何时候不经任何事先通知暂停或终止向该用户提供服务。<br />
        2、用户有下列行为或发布/散布/传播如下相关信息的，24猎在发现或接到投诉后，有权采取冻结账号、升级认证或以其他方式暂停向该用户提供服务，并要求用户承担相应的损害赔偿责任：<br />
        （1）涉及广告（寻求合作）、传销或直销等内容；<br />
        （2）涉及色情、淫秽内容；<br />
        （3）涉及违法/政治敏感内容；<br />
        （4）虚假信息，包括但不限于不真实的公司信息、薪资、个人简历、职位信息等；<br />
        （5）利用24猎提供的服务索取他人隐私；<br />
        （6）涉及人身攻击或其他侵害他人权益的内容；<br />
        （7）未成年人工作信息；<br />
        （8）招聘他人从事违法活动；<br />
        （9）以培训费、服装费等名义骗取求职者财物；<br /><br />
        （10）骚扰其他用户；<br />
        （11）不符合24猎相关服务性质的信息，如鸡汤、段子、水贴等；<br />
        （12）利用本平台可能存在的漏洞恶意充值直豆、获取道具等虚拟产品或服务；<br />
        （13）在本平台以外的任何第三方平台（包括但不限于淘宝、闲鱼等）售卖24猎账号服务的行为；<br />
        （14）涉嫌拖欠/未按法律规定支付薪资/劳务报酬的，或涉嫌具有其他可能损害劳动者或劳务人员合法权益的。（涉及农民工或涉众的均属于“情节严重”）本平台有权对前述情形进行处置，相关判断方式包括但不限于因上述行为被列入相关政府部门“黑名单”、被多名用户举报投诉或被新闻媒体曝光等情形；<br />
        （15）其他违反法律法规或国家政策以及损害24猎及其合法用户之合法权益的行为。<br />
        3、根据我国现行的法律法规等相关规定，如用户实施前述第（4）项“发布虚假信息”的，包括但不限于用户发布的职位信息与其实际招聘的职位不符的，与其发布的职位信息在内容、类型或其他方面并非一致或对应的甚至不存在，24猎随时有权拒绝向该用户提供服务，并可采取其他处理措施，包括但不限于“永久性封禁账号”、“永久性将其手机号等相关信息冻结”或“永久性加入‘黑名单’”等。<br />
        4、用户在发现本网站任何内容不符合法律规定，或不符合本用户协议规定的，用户有义务及时通知24猎。如果您发现您的个人信息被盗用、您的版权或者其他权利被侵害，请将此情况告知24猎并同时提供如下信息和材料：<br />
        （1）侵犯您权利的信息的网址，编号或其他可以找到该信息的细节；<br />
        （2）您是所述的版权或者其他权利的合法拥有者的权利证明；<br />
        （3）您的联系方式，包括联系人姓名，地址，电话号码和电子邮件；<br />
        （4）您的身份证复印件、营业执照等其他相关资料。
        经审查得到证实的，我们将及时删除相关信息。<br />
        5、24猎不对因下述任一情况而导致用户的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿：<br />
        （1） 使用或未能使用 24猎平台服务；<br /><br />
        （2） 第三方未经批准地使用您的账户或更改您的数据；<br /><br />
        （3） 您对24猎平台服务的误解；<br /><br />
        （4） 任何非因24猎的原因而引起的与 4猎平台服务有关的其它损失。<br />
        <br />
        八、隐私政策<br />
        24猎依法保护用户个人信息和隐私信息。有关隐私政策的内容，详见《24猎隐私条款》。<br />
        <br />
        九、关于用户在24猎的上传或发布的内容<br />
        1、用户在24猎上传或发布的内容（包括但不限于照片、文字、面试评价等），视为用户授予24猎免费、非独家的使用权，24猎有权为展示、传播及推广前述上传内容的目的，对上述内容进行复制、修改、出版等。该使用权持续至用户书面通知24猎不得继续使用。<br />
        2、因用户上传或张贴的内容侵犯他人权利，而导致任何第三方向24猎提出侵权或索赔要求的，用户应承担全部责任。<br />
        3、任何第三方对于用户在24猎的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与24猎无关。
        <br />
        十、关于添加微信和在线聊天等服务<br />
        1、用户在接受24猎提供与24猎用户进行添加微信、在线聊天等服务时，应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚，并保证所传输的信息真实性等底线。<br />
        2、用户通过本平台与他人在线聊天、拨打电话等商务场景下产生的文字等形式的沟通信息，24猎将会根据法律规定暂时存储，且仅用于投诉举报的处理、安全风控及离线暂存功能的实现。<br />
        3、24猎对该信息的采集、传输及存储均会采取加密、防泄露等相关措施，基于用户求职安全考虑，通常我们会存储3年。<br />
        4、为保护其他用户隐私，您不得下载、传播或公开发布本条规定的其他用户通讯信息，如聊天记录等。如因此造成24猎损失，或者侵害其他用户权益的，您应当承担违约责任或赔偿责任。<br />
        <br />
        十一、信息储存和限制<br />
        24猎有权制定一般措施及限制，包含但不限于软件服务将保留的聊天信息、发布版内容或其他上传内容的最长期间、每个账号可收发沟通讯息的最大数量及可收发的单个消息的大小。通过服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，24猎不承担任何责任。
        <br />
        十二、协议终止<br />
        用户若反对任何使用条款的内容或对之后使用条款修改有异议，或对24猎服务不满，用户有以下权利：不再使用24猎服务；结束用户使用24猎服务的资格；通知24猎停止该用户的服务。结束用户服务的同时，用户使用24猎服务的权利立即终止，24猎不再对用户承担任何义务。用户与
        24猎的协议关系终止后，24猎仍享有下列权利：<br />
        （1）继续保存用户未及时删除的信息及用户使用
        24猎平台服务期间发布的所有信息至法律规定的记录保存期满。<br />
        （2）用户在使用
        24猎平台服务期间存在违法行为或违反本协议和/或规则的行为的，24猎仍可依据本协议向用户主张权利、追究责任。<br />
        <br />
        十三、禁止商业行为<br />
        1、用户同意不对24猎提供的服务或服务的任何部分，进行复制、拷贝、出售、转售或用于任何其他商业目的。<br />
        2、禁止通过职位向应聘者收费，如有不实，我们将结束用户使用24猎服务的资格。<br />
        <br />
        十四、违约责任<br />
        1、用户使用虚假身份信息、公司信息进行注册，发布虚假招聘、求职信息，发布含有传销、色情、反动等严重违法内容，对外传播聊天等通讯记录等行为，视为严重违反本协议，应当承担给24猎造成的经济损失和名誉损失。<br />
        2、因用户通过24猎提供的服务上传或发布内容、违反本服务条款、或侵害他人任何合法权益而导致任何第三人24猎提出任何索赔或请求，用户应当赔偿24猎或其他合作伙伴的损失，包括但不限于赔偿金额、律师费和合理的调查费用等。<br />
        3、用户在投诉其他用户有违法行为或违反本使用条款情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给24猎造成损失的，投诉人应对24猎承担相应的赔偿责任。<br />
        <br />
        十五、本协议条款的变更和修改
        24猎有权依法随时对本协议的任何条款进行变更和修改。一旦发生条款变动，我们将在24猎软件内进行更新，用户如果不同意条款的修改，应主动停止使用24猎或申请注销24猎账号，如未使用的付费权益将在注销后清空。否则，如果用户继续使用用户账号，则视为用户已经接受本协议全部条款的修改。
        <br />
        十六、不可抗力<br />
        1、“不可抗力”是指24猎不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误24猎根据本使用条款履行其全部或部分义务。该事件包括但不限于信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务等。不可抗力可能导致24猎无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄漏等不利后果。<br />
        2、遭受不可抗力事件时，24猎可中止履行本协议项下的义务直至不可抗力的影响消除为止，并且不因此承担违约责任；但应尽最大努力克服该事件，减轻其负面影响。<br />
        <br />
        十七、隐私政策<br />
        1、24猎将在平台公布并不时修订隐私条款，隐私条款构成本协议的有效组成部分。用户需要知悉并承诺在使用
        24猎的服务过程中，遵守《24猎隐私条款》。<br />
        2、24猎要求各搜索引擎遵循行业规范，即“拒绝 Robots 访问标准”(Robots
        ExclusionStandard)，否则将视你的抓取行为是对我网站财产权利和知识产权的侵犯，有权通过法律诉讼维护网站利益。
        <br />
        十八、知识产权声明<br />
        1、“24猎”为24猎所在公司及其关联公司的商标，受法律保护，任何人不得擅自使用。凡侵犯本公司版权等知识产权的，24猎将依法追究其相关法律责任。24猎服务中提供的内容、图表、版式设计等所呈现出的全部或部分可单独作为作品保护的内容（包括但不限于网页、文字、图片、图像、地图、图标、音频、视频、图表、版面设计、电子文档、数据资料等），以及开发、运营和维护本网站运营的各种源代码、执行程序、系统和数据均受到中国版权法律、法规及相关国际条约的保护。除另有书面声明以外，以上信息和内容的知识产权归24猎所有，未经24猎事先书面同意，不允许全部或部分复制、转载、使用、修改、链接、汇编、发表、抓取或以其他任何方式使用。<br />
        2、用户通过24猎服务上传、提交、存储或发布的任何内容（包括但不限于文字、图片、视频、音频、动画等）的知识产权归属您或原始著作权人所有，您的上传、提交、存储或发布行为不会侵犯他人的知识产权或其他合法权益。因您通过24猎上传、发布的任何内容导致的知识产权侵权问题，您将承担全部责任；如24猎提供方因第三方知识产权维权而产生损失，您将面临等额赔偿的责任。<br />
        3、用户同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。<br />
        4、用户通过24猎平台发布的信息或内容，并不代表
        24猎之意见及观点，也不意味着24猎赞同其观点或证实其内容的真实性。24猎有权删除网站内各类不符合法律或本协议规定的信息或内容，而保留不通知用户的权利。<br />
        5、用户通过24猎平台发布的信息、文字、图片等资料均由24猎用户提供，其真实性、准确性和合法性由信息发布人负责。24猎不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，24猎对此不承担责任。<br />
        6、除法律另有强制性规定外，未经24猎明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、传播、展示、镜像、上载、下载、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则24猎有权追究其法律责任。<br />
        7、24猎平台以下内容不可任意转载：<br />
        （1）本平台内发布的所有信息；<br />
        （2）作出不得转载或未经许可不得转载声明的内容；<br />
        （3）本平台中特有的图形、标志、页面风格、编排方式、程序等；<br />
        （4）本平台中必须具有特别授权或具有用户资格方可知晓的内容；<br />
        （5）其他法律不允许或本平台认为不适合转载的内容。<br />
        8、对于不当引用24猎网站内容而引起的纷争等或因纠纷等造成的任何损失，24猎不承担相关法律责任。对不遵守本声明的用户或其他违法、恶意使用24猎网站内容者，24猎保留追究其法律责任的权利。<br />
        9、24猎平台相关声明版权及其修改权、更新权和法律允许范围内的解释权均属24猎所有。
        <br />
        十九、通知<br />
        24猎向其用户发出的通知，将采用消息弹窗或页面公告等形式。
        <br />
        二十、法律适用、管辖和其他<br />
        1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律，如法律无相关规定的，则应参照通用国际商业惯例和（或）行业惯例。<br />
        2、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。<br />
        3、因本协议产生之争议、纠纷，应由24猎与您友好协商解决；协商不成的，应依照中华人民共和国法律予以处理，并以北京市房山区人民法院为第一审管辖法院，按照中华人民共和国法律，因级别管辖的规定，北京市房山区人民法院无权管辖的，以其上级法院作为第一审管辖法院。
        为维护相关权利人利益，北京三门课科技有限公司为用户及相关权利人提供了投诉渠道（包括但不限于通过客服、24猎网站首页下方的微信及企业邮箱或者通过24猎的公众号留言（公众号：24猎）等渠道与24猎取得联系）。基于网络用户间的公平考量，同时也为了使网络用户的投诉能够及时得到处理，避免被当做垃圾邮件屏蔽，北京三门课科技有限公司拒绝接收和处理所有以电子邮件形式发送给本公司员工个人的网络侵权通知，更不承诺对发送给本公司员工个人的电子邮件侵权通知进行优先处理，特此告知。<br />
      </div>
      <!-- 零工 -->
      <div v-else>
        用户服务协议 特别提示<br /><br />
        北京三门课科技有限公司（以下简称“我们”）在此特别提醒您在成为用户之前，请认真阅读《用户服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您谨慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br />
        本协议约定我们与用户之间关于24猎软件和相关产品或服务（以下简称“服务”）的权利义务。<br />
        “用户”是指注册、登录、使用本服务的个人。本协议可由我们随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不另行通知，用户可在本软件查阅最新版协议条款。在我们协议修改后，如果用户不接受修改后的条款，请立即停止使用我们提供的服务，用户继续使用我们提供的服务将被视为接受修改后的协议。<br />
        第一条账号注册<br />
        1、您在使用本服务前需要注册一个“用户”账号。“用户”账号应当使用手机号码绑定注册，请您使用尚未与24猎账号绑定的手机号码，且未被我们根据本协议封禁的手机号码注册“用户”账号。我们可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。<br />
        2、18周岁以下用户在注册“零工”账号时需经法定代理人同意。若您接受本协议即视为已满18周岁，或未满18周岁但法定代理人同意您的注册。<br />
        3、用户注册时应当授权我们使用用户相关个人信息注册账号。用户完成注册即表明用户同意我们提取、储存、向特定用户公开及使用用户的相关信息，包括但不限于用户的姓名、性别、年龄、身份证号、身份证扫描图像、地址等。<br />
        4、用户同意将以上信息授权我们与合作方留存、使用并进行相关只是产权开发；我们同意对这些信息的使用将受限于第三方用户个人隐私信息保护的约束；若因第三方原因造成用户个人隐私信息泄露等侵害用户权利的情况，我们不承担法律责任。<br />
        5、鉴于账号的绑定注册方式，您同意我们在注册时将使用您提供或授权的手机号码及/或自动提取您的手机号码及自动提取您的收集设备识别码等信息用于注册。<br />
        6、平台为您提供工作岗位大数据匹配，双方不是劳务关系。为避免误解，特说明如下：24猎平台提供的并不是劳务派遣服务、劳务外包服务、劳务中介服务，而是企业客户和零工之间提供信息及技术支持，协助企业客户和零工达成工作匹配的网络信息技术平台。零工在工作中，因企业提供的工作不符合劳动法及当地法律法规，所产生的责任将全部由企业客户承担。<br />
        7、您注册账号后如果长期不登录该账号，平台有权收回、注销该账号或设定账号功能限制，以免造成资源浪费，由此带来的损失由您自行承担。<br />
        第二条服务内容<br />
        1、服务内容由我们根据实际情况提供，包括但不限于授权用户通过其账号进行发布工作、搜索工作、接受工作等。我们对用户每次工作进行人工智能分析，形成记录。我们对其提供的服务予以变更，且我们提供的服务内容随时变更；用户将收到我们关于服务变更的通知。<br />
        2、24猎服务平台向企业客户提供大数据匹配的零工信息，包括但不限于姓名、年龄等，并同时向零工提供企业客户的实时用工需求，包括但不限于岗位职责、薪资、工作地点、工作时长等，以便促成企业客户和零工之间达成双方满意的大数据人力资源匹配服务。<br />
        第三条 用户个人信息保护<br />
        1、用户在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的个人信息，如法律法规、规章规范性文件（以下简称“法律法规）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中收到限制。<br />
        2、用户个人信息包括：<br />
        （1）用户自行提供的用户个人信息（如注册时授权的手机号码等个人信息，使用服务时提供的共享信息）；<br />
        （2）其他分享的用户个人信息；<br />
        （3）我们为提供服务而收集或通过合作公司收集的用户个人信息（如由第三方技术采集的身份证信息等，使用服务时系统自动采集的设备或软件信息，浏览历史信息等技术信息，用户开启定位功能并使用服务时的地理位置信息等）。<br />
        其中个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在我们服务器端的基本记录信息、个人隐私信息范围外的其他普通信息，以及用户同意公开的上述隐私信息。用户同意我们与合作公司、关联公司收集、留存、传输、使用用户的个人隐私信息及非隐私信息。<br />
        3、尊重用户个人信息的私有化是我们的一贯制度，我们将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者我们发现存在发生前述情形的可能时，我们将及时采取补救措施并告知用户，用户如发现前述情形亦需与我们联系。<br />
        4、我们未经用户同意不向任何第三方公开、透露用户个人隐私信息。但以下特定情形除外：<br />
        （1）我们根据法律法规规定或有权机关的指示提供用户的个人隐私信息；<br />
        （2）由于用户将其用户密码告知他人或与他们共享注册账号的密码，由此导致的任何个人信息的泄露，或其他非因我们原因导致的个人隐私信息的泄露；<br />
        （3）用户自行向第三方公开其个人隐私信息；<br />
        （4）用户与我们及合作单位之间就用户个人隐私信息的使用公开达成约定，我们因此向合作单位公开用户个人隐私信息；<br />
        （5）任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；<br />
        （6）用户个人信息已经经过处理无法识别特定个人且不能复原。<br />
        5、用户同意我们可在以下事项中使用用户的个人隐私信息：<br />
        （1）我们向用户及时发送重要通知，如软件更新、本协议条款的变更；<br />
        （2）我们内部进行审计、数据分析和研究等，以改进我们的产品、服务和与用户之间的沟通；<br />
        （3）依本协议约定，我们管理、审查用户信息及进行处理措施；<br />
        （4）使用法律法规规定的其他事项。<br />
        除上述事项外，如未取得用户事先同意，我们不会将用户个人隐私信息使用于任何其他用途。<br />
        6、我们重视对未成年人个人信息的保护。我们将依赖用户提供的个人信息判断用户是否为未成年人。任何18周岁以下的未成年人注册账号或使用本服务应事先取得家长或其他法定代理人的书面同意。除根据法律法规的规定及有权机关的指示披露外，我们不会使用或向任何第三方透露未成年的个人信息。除本协议约定的例外情形外，未经法定代理人事先同意，我们不会使用或向任何第三方透露未成年人的个人信息。<br />
        7、为了改善我们的技术和服务，向用户提供更好的服务体验，我们或可会自行收集使用或向第三方提供用户的非个人隐私信息。<br />
        8、我们保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。<br />
        第四条 内容规范<br />
        1、本条所述内容是指用户使用本服务过程中所制作、上传、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语言、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。<br />
        2、用户不得利用账号或本服务制作、上传、复制、发布、传播如下法律、法规和政策禁止的内容：<br />
        （1）反对宪法所确定的基本原则的；<br />
        （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一性的；<br />
        （3）损害国家荣誉和利益的；<br />
        （4）煽动民族仇恨、民族歧视，破坏民族团结的；<br />
        （5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
        （6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
        （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆罪犯的；<br />
        （8）侮辱或者诽谤他人，侵害他人合法权益的；<br />
        （9）不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实底线的“七条底线”要求的；<br />
        （10）含法律、行政法规禁止的其他内容的信息。<br />
        3、用户不得利用账号或本服务制作、上传、复制、发布、传播如下干扰我们正常运营，以及侵犯其他用户或第三方合法权益的内容：<br />
        （1）含有任何性或性暗示的； （2）含有辱骂、恐吓、威胁内容的；<br />
        （3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br />
        （4）涉及他人隐私、个人信息或资料的；<br />
        （5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br />
        （6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br />
        第五条 使用规则<br />
        1、用户在本服务中或通过本服务所传送、发布的任何内容并不反应或代表，也不得被视为反映或代表我们的观点、立场或政策，我们对此不承担任何责任。<br />
        2、用户不得利用账号或本服务进行如下行为：<br />
        （1）提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br />
        （2）强制、诱导其他用户关注、点击链接页面或分享信息的；<br />
        （3）虚构事实、隐瞒真相以误导、欺骗他人的；<br />
        （4）利用技术手段批量建立虚假账号的；<br />
        （5）利用账号或本服务从事任何违法犯罪活动的；<br />
        （6）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否未商业目的；<br />
        （7）其他违反法律法规规定、侵犯其他用户合法权益、干扰我们正常运营或我们未明示授权的行为。<br />
        3、用户须对利用账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性、等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与我们无关。如因此给我们或第三方造成损害的，用户应当依法予以赔偿。<br />
        4、用户需要保证向我们提供的信息真实、准确和完整。为实现零工服务功能，我们有权验证您所提供的信息，并有权根据验证结果决定是否向您提供服务。<br />
        5、您使用24猎平台服务，即表示您还同意以下事项:<br />
        （1）您将严格遵守本协议以及平台规则的约定;<br />
        （2）您将遵守所有适用的法律法规规定及公序良俗;<br />
        （3）您不会将平台服务或应用程序用于非法目的，包括(但不限于)发送或存储任何非法资料或者用于欺诈目的;<br />
        （4）您不会利用平台服务或应用程序侵犯他人合法权利，不会骚扰、妨碍他人或给他人造成不便;<br />
        （5）您不会影响本平台网络的正常运行或者尝试危害平台服务或应用程序;<br />
        （6）当本平台提出合理请求时，您会提供身份证明资料。<br />
        （7）您认可与我们建立协议关系并接受其服务。<br />
        6、除非我们书面许可，用户不得从事下列任一行为：<br />
        （1）删除软件及其副本上关于著作权的信息；<br />
        （2）对软件进行反向工程、反向编汇、反向编译，或者以其他方式尝试发现软件的源代码；<br />
        （3）对我们拥有的知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、编汇、发表、出版、建立镜像站点等；<br />
        （4）对软件或者软件运行中的指令、数据、软件运行过程中客户端和服务器的交互数据，以及软件运行所必须的系统数据，进行复制、修改、增加、删除、挂接运行或创作热火你和衍生作品，形式包括但不限于使用插件、外挂或非我们授权的第三方工具/服务接入软件或相关系统；<br />
        （5）通过修改或伪造运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br />
        （6）通过非我们开发、授权的第三方软件、插件、外挂、系统、登录或使用我们的软件及服务，或制作、发布、传播非我们发布、授权的第三方软件、插件、外挂、系统。<br />
        第六条 账户管理<br />
        1、账号的所有权归我们所有，用户完成申请注册手续后，获得账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。我们因经营需要，有权收回用户的账号。<br /><br />
        2、用户可以通过查看与编辑个人信息页查询、更改、隐藏账户上的个人资料、注册信息及传送内容等。<br />
        3、用户有责任妥善保管注册账号信息及账号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的账号或密码。在用户怀疑他人使用其他账号或密码时，用户同意立即通知我们。<br />
        4、用户遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，我们在通知用户后有权依据协议中断或终止对违约用户账号提供服务。同时，我们保留在任何时候收回账号的权利。<br />
        5、为保护用户权利与避免产生任何争议与纠纷，账号的注销需要根据账号实际情况提交相关说明，必要时我们会要求用户进行实名认证，确保完成账号注销流程。<br />
        第七条 数据存储
        1、我们不对用户在本服务中相关数据的删除或存储失败负责。<br />
        2、我们可以根据实际情况自行决定用户在本服务中数据的最长存储期限，并在服务器上为其分配数据最大存储空间等。<br />
        3、如用户停止使用本服务或本服务终止，我们可以从服务器上永久地删除用户的数据。本服务停止、终止后，我们没有义务向用户返还任何数据。<br />
        第八条 风险承担<br />
        1、用户理解且同意，我们仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册账号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对我们及本服务中的内容自行加以判断，并承担因使用内同而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。我们无法且不会对因用户行为而导致的任何损失或损害承担责任。如用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向我们举报或投诉，邮箱为qudao24lie@126.com，我们将依本协议约定进行处理。<br />
        2、用户理解并同意，因业务发展需要，我们保留单方面对服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。<br />
        第九条 知识产权声明<br />
        1、我们在本服务中提供的内容（包括但不限于网页、文字、图像、音频、视频、图表等）的知识产权均归我们所有。<br />
        2、除另有特别声明外，我们提供本服务时所依托软件的著作权、专利权及其他知识产权均归我们所有。<br />
        3、我们在本服务中所涉及的图形、文字或其组成，以及其他标志及产品、服务名称，其著作权或商标权归我们所有。未经我们事先书面同意，用户不得将我们的标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理我们标识的行为。<br />
        4、我们对用户个人信息及衍生信息的使用可能产生的知识产权，归我们享有。<br />
        第十条 法律责任<br />
        1、如果我们发现或收到他人举报或投诉用户违反本协议约定的，我们有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。如您违反平台用户规则，我们有权依据平台规则追究您的违规责任。<br />
        2、因违反用户协议被封禁的用户，可以自行向我们查询封禁期限并进行申诉，我们将对申诉进行审查，并自行合理判断决定是否变更处罚措施。<br />
        3、用户理解并同意，我们有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br />
        4、用户因提供劳务受到损害或致使用工单位受到损害，造成我们依据法律规定需要承担责任并赔偿损失的，我们可以向直接责任人追偿。<br />
        5、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿我们和我们合作公司、关联公司，并使之免受损害。<br />
        第十一条 责任限制<br />
        1、在24猎平台上向您提供的信息、推荐的服务仅供您参考。我们将在合理的范围内尽力保证该等信息准确，但无法保证其中没有任何错误、缺陷、恶意软件和病毒。<br />
        2、您知悉并确认，您通过24猎平台可获得的零工服务，由我们根据您发出的岗位需求信息，经过后台大数据信息处理，在用户终端上提供可使用的零工信息，主导最终匹配成功的企业客户按照平台规则向您提供零工服务。我们根据适用的法律法规就上述服务承担相应责任。为更好的保障用户权益，我们将通过平台规范的方式约束平台中的参与方严格遵守平台规则，保障用户权益，我们负责平台规则的合理化及监督规则的实施。<br />
        3、24猎平台在以上零工服务区间内，根据适用法律法规的要求承担相应的法律责任。平台参与方在零工服务提供区间内，根据法律法规的要求承担相应的法律责任。同时，为提升服务品质，保障平台用户利益，平台有权要求企业客户按照国家相关法律法规提供完善合规的用工保障。<br />
        第十二条 不可抗力及其他免责事由<br />
        1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，我们及合作单位在法律允许范围内免责。<br />
        2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其他软件或访问的其他网站中含有病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭受损失和骚扰。<br />
        3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路等原因造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方的任何损失，我们不承担任何责任。<br />
        4、用户理解并确认，在使用本服务过程中存在来自任何人的包括误导性的、欺骗性的、威胁性的、诽谤性的、或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，我们不承担任何责任。<br />
        5、用户理解并确认，我们需要定期或不定期地对平台和相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，我们无需为此承担任何责任，但我们应事先通知。<br />
        6、我们依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成我们的义务或承诺，我们不能保证及时发现违法违规或违约行为或进行相应处理。<br />
        7、用户理解并确认，对于我们向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，我们无需承担任何责任：<br />
        （1）我们向用户免费提供的服务；
        （2）我们向用户赠送的任何产品或者服务。<br />
        8、在任何情况下，我们均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用产品或本服务而遭受的利润损失，承担责任（即使我们已被告知该等损失的可能性亦然）。<br />
        第十三条 服务的变更、中断、终止<br />
        1、鉴于网络服务的特殊性，用户同意我们有权随时变更、中断或终止部分或全部的服务。我们应当在变更、中断或终止之前通知用户。<br />
        2、如发生下列任何一种情形，我们有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：<br />
        （1）根据法律法规用户应提交真实信息，而用户提供的个人资料不真实，或与注册时信息不一致又未能提供合理证明；<br />
        （2）用户违反相关法律法规或本协议的约定；<br />
        （3）按照法律法规或有权机关的要求；
        （4）出于安全的原因或其他必要情形。<br />
        第十四条 其他<br />
        1、我们郑重提醒用户注意本协议中免除我们责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定代理人的陪同下阅读本协议。<br />
        2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。通过访问24猎平台使用平台提供的或其他合作服务提供商提供的服务，即表示您同意该访问或服务受中华人民共和国法律约束，且您同意受中华人民共和国法院的管辖。若用户和我们之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交我们所在地有管辖权的人民法院管辖。<br />
        3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。<br />
      </div>
      <div class="btjh">
        <el-button @click="calk">确认阅读并返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      edition: 0,
      // msg: 'Welcome to Your Vue.js App'
    };
  },
  methods: {
    calk() {
      this.$router.go(-1);
    },
  },

  created() {
    this.edition = this.$route.query.edition;
    console.log(this.edition);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  font-size: 14px;
  background: #fff;

  /* margin: 0 auto; */
  padding: 50px;
}
.main h4 {
  width: 1300px;
  margin: 0 auto;
}

.main section {
  width: 1300px;
  margin: 0 auto;
}

.main p {
  width: 1300px;
  margin: 0 auto;
}

section {
  padding: 10px 0;
}
.btjh {
  text-align: center;
  margin-top: 20px;
}
</style>
